var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"cr-modal"},[_c('v-card-title',[_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"100%"}},[_c('h2',{staticClass:"d-flex",staticStyle:{"width":"calc(100% - 24px)"}},[_vm._v("Complete Call")]),_c('v-icon',{staticStyle:{"width":"24px","cursor":"pointer"},attrs:{"color":"white"},on:{"click":_vm.close}},[_vm._v(" close ")])],1)]),_c('v-card-text',[_c('v-form',{ref:"complete-call-form"},[_c('p',[_vm._v("Please answer the dropdowns below and complete the call.")]),_vm._l((_vm.reasonTypeLevels),function(reasonType,i){return _c('div',{key:`reason-type-level-${i}-${_vm.reloader}`},[(
            i === 0 ||
            (i <= _vm.level &&
              reasonType.filter((v) => {
                return (
                  v.parentClassificationId === _vm.reasons[i - 1].classificationId
                )
              }).length)
          )?_c('CRSelect',{key:`call-center-complete-call-reason-select-${i}-${_vm.reloader}`,attrs:{"id":`call-center-complete-call-reason-select-${i}`,"items":i === 0
              ? reasonType
              : reasonType.filter(
                  (v) =>
                    _vm.reasons.length > i - 1 &&
                    v.parentClassificationId ===
                      _vm.reasons[i - 1].classificationId
                ),"item-text":"label","return-object":"","label":i === 0 ? 'Reason' : '',"rules":[
            _vm.isRequired(true, _vm.isNotEmpty, {
              req: 'Reason For Call is Required',
              error: 'Reason For Call is Required',
            }),
          ],"placeholder":_vm.reasonPlaceholderText},on:{"change":(_) => _vm.onReasonSelect(i)},model:{value:(_vm.reasons[i]),callback:function ($$v) {_vm.$set(_vm.reasons, i, $$v)},expression:"reasons[i]"}}):_vm._e()],1)})],2)],1),_c('v-card-actions',[_c('v-layout',{attrs:{"justify-end":""}},[_c('div',{staticClass:"button-cont"},[_c('div',{staticStyle:{"margin-right":"-1.5%"}},[_c('v-btn',{attrs:{"id":"call-center-complete-call-complete-button","color":"primary"},on:{"click":_vm.completeCall}},[_vm._v(" Complete ")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }