<template>
  <v-card class="cr-modal">
    <v-card-title>
      <div class="d-flex align-center" style="width: 100%">
        <h2 class="d-flex" style="width: calc(100% - 24px)">Complete Call</h2>
        <v-icon
          color="white"
          style="width: 24px; cursor: pointer"
          @click="close"
        >
          close
        </v-icon>
      </div>
    </v-card-title>
    <v-card-text>
      <v-form ref="complete-call-form">
        <p>Please answer the dropdowns below and complete the call.</p>
        <div
          v-for="(reasonType, i) in reasonTypeLevels"
          :key="`reason-type-level-${i}-${reloader}`"
        >
          <CRSelect
            v-if="
              i === 0 ||
              (i <= level &&
                reasonType.filter((v) => {
                  return (
                    v.parentClassificationId === reasons[i - 1].classificationId
                  )
                }).length)
            "
            :id="`call-center-complete-call-reason-select-${i}`"
            :key="`call-center-complete-call-reason-select-${i}-${reloader}`"
            v-model="reasons[i]"
            :items="
              i === 0
                ? reasonType
                : reasonType.filter(
                    (v) =>
                      reasons.length > i - 1 &&
                      v.parentClassificationId ===
                        reasons[i - 1].classificationId
                  )
            "
            item-text="label"
            return-object
            :label="i === 0 ? 'Reason' : ''"
            :rules="[
              isRequired(true, isNotEmpty, {
                req: 'Reason For Call is Required',
                error: 'Reason For Call is Required',
              }),
            ]"
            :placeholder="reasonPlaceholderText"
            @change="(_) => onReasonSelect(i)"
          ></CRSelect>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-layout justify-end>
        <div class="button-cont">
          <div style="margin-right: -1.5%">
            <v-btn
              id="call-center-complete-call-complete-button"
              color="primary"
              @click="completeCall"
            >
              Complete
            </v-btn>
          </div>
        </div>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import calls from '@/services/calls'
import { EventBus } from '@/utils/event-bus'
import { authComputed, callCenterComputed } from '@/state/helpers'
import { isRequired, isNotEmpty } from '@/utils/validators'

export default {
  props: {
    endedCallSid: {
      type: String,
      required: true,
    },
    reasonTypeLevels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      callId: null,
      reasons: [],
      level: 0,
      reloader: 0,
      broadcastChannel: null,
      isRequired,
      isNotEmpty,
    }
  },
  computed: {
    ...authComputed,
    ...callCenterComputed,
    reasonPlaceholderText() {
      return `Please select the reason for the ${
        this.isTransferred ? 'transfer' : 'call'
      }`
    },
  },
  async mounted() {
    const params = {
      callSid: this.endedCallSid,
      userId: this.currentUser.userId,
    }
    const callIdResponse = await calls.getCallIdByCallSid(params)
    this.callId = callIdResponse.data?.call?.callId
    this.broadcastChannel = new BroadcastChannel('flex-channel')
    this.broadcastChannel.addEventListener('message', (event) => {
      if (event.data?.action_name === 'close_dialog') {
        this.closeNoSubmit()
      }
    })
    for (let i = 0; i < this.reasonTypeLevels.length; i++) {
      this.reasons.push({ classificationId: 0 })
    }
  },
  methods: {
    async completeCall() {
      const valid = this.$refs['complete-call-form'].validate()
      const defaultSatisfaction = {
        id: 1,
        label: 'Very Satisfied',
        key: 'very_satisfied',
        description: 'Customer is satisfied with their call',
      }
      if (valid) {
        const payload = {
          classificationId: this.reasons[this.level - 1].classificationId,
          satisfactionType: defaultSatisfaction,
          callId: this.callId,
        }
        try {
          await calls.completeCall(payload)
          this.close()
        } catch (error) {
          this.close()
        }
      }
    },
    close() {
      EventBus.$emit('call-completed')
      this.$store.dispatch('app/closeDialog')
      this.broadcastChannel.postMessage({
        type: 'cr_internal',
        action_name: 'close_dialog',
      })
    },
    closeNoSubmit() {
      this.$store.dispatch('app/closeDialog')
    },
    onReasonSelect(i) {
      for (let j = i + 1; j < this.reasons.length; j++)
        this.reasons[j] = { classificationId: 0 }
      this.level = i + 1
      this.reloader++
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep label {
  font-weight: bold;
}
</style>
